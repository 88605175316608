import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapefour from "../images/bannerElement/white-design-element-saas.png"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import conversica from "../images/conversica-saas.svg"
import creativeContent from "../images/creative-content.svg"
import ComprehensiveBusinessAnalysis from "../images/icons/Saas/ComprehensiveBusinessAnalysis.svg"
import DataDrivenTargeting from "../images/icons/Saas/DataDrivenTargeting.svg"
import MultiChannelMarketing from "../images/icons/Saas/MultiChannelMarketing.svg"
import OngoingOptimization from "../images/icons/Saas/OngoingOptimization.svg"
import ResultsFocused from "../images/icons/Saas/Results-focused-execution.svg"
import StrategicPositioning from "../images/icons/Saas/StrategicPositioning.svg"
import marketingAutomation from "../images/marketing-automation.svg"
import openprise from "../images/openprise-saas.svg"
import paidMarketing from "../images/paid-marketing.svg"
import seo from "../images/seo.svg"
import webDevelopment from "../images/web-development.svg"
import workato from "../images/workato-saas.svg"
import "../styles/sass.css"

export default function Sass() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Saas | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div className="sassStyles">
          <section id="Banner">
            <div className="container">
              <div className="banner-holder">
                <div className="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div className="title">
                  <div className="banner-title">
                    <h1>SaaS marketing made easy. Start growing today.</h1>
                    <p>
                      Increase leads, boost sales, and achieve your marketing
                      goals faster.
                    </p>
                    <a
                      className="button"
                      id="3dsolutionvideo"
                      href="/contact-us/"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div className="container">
              <h2>Cut through the noise in the SaaS market</h2>
              <p>
                As a SaaS company, simply having a great product is no longer
                enough. You need marketing strategies that effectively engage
                your ideal customers and help you stand out from competitors.
                Position
                <sup>2</sup> is the partner you need to accelerate your SaaS
                growth.
              </p>
            </div>
          </section>
          <section id="WeDeliver">
            <div className="container">
              <h2>Our formula for SaaS success</h2>
              <p>
                We take a comprehensive, data-driven approach covering all key
                aspects of growth marketing to get your message in front of the
                right people at the right time.
              </p>
              <div className="horizontalImageIconSec">
                <div className="moduleWrap">
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={ComprehensiveBusinessAnalysis}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Comprehensive Business Analysis</h3>
                      <p>
                        We dive deep into your specific goals, tech stack,
                        processes, and audiences to gain a holistic
                        understanding.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={MultiChannelMarketing}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Multi-channel Marketing</h3>
                      <p>
                        We craft integrated strategies across channels to engage
                        your audience where they are most active.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={DataDrivenTargeting} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Data-driven Targeting</h3>
                      <p>
                        We leverage research, analytics, and segmentation to
                        define and reach your ideal customer profiles precisely.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={ResultsFocused} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Results-focused Execution</h3>
                      <p>
                        We implement proven performance marketing strategies
                        tailored to your KPIs for tangible ROI.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={StrategicPositioning}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Strategic Positioning</h3>
                      <p>
                        We shape messaging, branding, and positioning to
                        communicate your unique value proposition.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={OngoingOptimization} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Ongoing Optimization</h3>
                      <p>
                        We continuously test, analyze, and refine campaigns to
                        drive consistent growth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="IndustryLeaders">
            <div className="container">
              <div className="innerWrap">
                <h2 className="title">Proven for industry leaders</h2>
                <div className="logos">
                  <img src={workato} />
                  <img src={conversica} />
                  <img src={openprise} />
                </div>
              </div>
            </div>
          </section>
          <section id="OurServices">
            <div className="container">
              <h2>Our end-to-end services</h2>
              <div className="tilesWrap">
                <a
                  className="eachtiles"
                  href="/web-design-and-development/"
                >
                  <img src={webDevelopment} />
                  <span>Website Development</span>
                </a>
                <a
                  className="eachtiles"
                  href="/creative-assets/"
                >
                  <img src={creativeContent} />
                  <span>Creative & Content</span>
                </a>
                <a
                  className="eachtiles"
                  href="/paid-marketing/"
                >
                  <img src={paidMarketing} />
                  <span>Paid Advertising</span>
                </a>
                <a
                  className="eachtiles"
                  href="/seo/"
                >
                  <img src={seo} />
                  <span>SEO</span>
                </a>
                <a
                  className="eachtiles"
                  href="/marketing-automation/"
                >
                  <img src={marketingAutomation} />
                  <span>Marketing Automation</span>
                </a>
              </div>
              <h2>We are the partner you need</h2>
              <p>
                Position<sup>2</sup> provides SaaS companies with the same
                cutting-edge marketing approaches we use ourselves. Work with
                experts who know what success looks like in a crowded space.
              </p>
            </div>
          </section>
          <section id="contactus-section">
            <div className="container">
              <h2>Ready to grow your SaaS business?</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
